import React, { Component } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import {
  FacebookShareButton,
  FacebookShareCount,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  RedditShareButton,
  RedditIcon,
  RedditShareCount,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon
} from "react-share";

import { Layout, Seo, PostDate, Hero, Sidebar } from "../components"

import styles from "./post.module.scss"

class Post extends Component {
  render() {
    const post = this.props.data.wordpressPost
    const postdate = new Date(post.date)
    const posturl = this.props.data.site.siteMetadata.url + "/" + post.slug

    const socialImage = post.featured_media && post.featured_media.localFile && post.featured_media.localFile.childImageSharp.fixed
      ? post.featured_media.localFile.childImageSharp.fixed
      : null

    let featImage = ""
    if(post.featured_media && post.featured_media.localFile) {
      featImage = post.featured_media.localFile.childImageSharp.fluid
    }
    return (
      <Layout>
        <Seo title={post.title} image={socialImage} description={post.excerpt} pathname={this.props.location.pathname} />
        { featImage.length ??
          <Hero image={featImage} />
        }
        <section className="section">
          <div className="container">
            <div className="columns has-background-white">
              <div className="column is-two-thirds has-background-white content">
                <article className={styles.article}>
                  <h1 dangerouslySetInnerHTML={{ __html: post.title }} />
                  <PostDate date={postdate} /> <span className="is-size-7">by {post.author.name}</span>
                  <div className={styles.content} dangerouslySetInnerHTML={{ __html: post.content }} />
                  <h2>Share this</h2>
                  <footer className="card-footer">
                    <div className="card-footer-item">
                      <FacebookShareButton url={posturl}>
                        <FacebookIcon size="30" /><br />
                        <FacebookShareCount url={posturl} />
                      </FacebookShareButton>
                    </div>
                    <div className="card-footer-item">
                      <RedditShareButton url={posturl}>
                        <RedditIcon size="30" /><br />
                        <RedditShareCount url={posturl} />
                      </RedditShareButton>
                    </div>
                    <div className="card-footer-item">
                      <TwitterShareButton url={posturl}>
                        <TwitterIcon size="30" /><br />
                      </TwitterShareButton>
                    </div>
                    <div className="card-footer-item">
                      <LinkedinShareButton url={posturl}>
                        <LinkedinIcon size="30" /><br />
                      </LinkedinShareButton>
                    </div>
                    <div className="card-footer-item">
                      <WhatsappShareButton url={posturl}>
                        <WhatsappIcon size="30" /><br />
                      </WhatsappShareButton>
                    </div>
                  </footer>
                </article>
              </div>
              <div className="has-background-grey-lighter column">
                    <Sidebar />
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

Post.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default Post

export const postQuery = graphql`
  query($id: String!) {
    wordpressPost(id: { eq: $id }) {
      title
      content
      excerpt
      date
      slug
      author {
        name
      }
      featured_media {
        localFile {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
            fixed(width: 1200, height: 630) {
              src
              height
              width
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        url
      }
    }
  }
`